export default [
  {
    url: "/",
    name: "Dashboard",
    slug: "dashboard",
    icon: "PieChartIcon",
  },
  {
    header: "Instituciones",
    items: [
      {
        name: "Cafeterías",
        icon: "CoffeeIcon",
        submenu: [
          {
            url: "/institutions",
            name: "Cafeterías",
            slug: "institutions",
            icon: "CoffeeIcon",
          },
          {
            url: "/categories",
            name: "Categorías",
            slug: "categories",
            icon: "MenuIcon",
          },
        ]
      },
      {
        url: "/institution/schools",
        name: "Escuelas",
        slug: "schools",
        icon: "HomeIcon"
      },
      // {
      //   url: "/requestsAdmin",
      //   name: "Solicitudes de Pago",
      //   slug: "requestsAdmin",
      //   icon: "ClipboardIcon"
      // },
    ]
  },
  {
    header: 'Servicios',
    items: [
      {
        url: '/services/subscribe',
        name: 'Asociar Servicios',
        slug: 'services',
        icon: 'ToolIcon'
      },
      {
        url: "/services",
        name: "Servicios",
        slug: "services",
        icon: "ToolIcon",
      },
    ]
  },
  {
    header: "Tienda",
    icon: "MoreHorizontalIcon",
    i18n: "Others",
    items: [
      {
        url: "/marketplace/stores",
        name: "Tiendas",
        slug: "stores",
        icon: "ShoppingCartIcon",
      },
      {
        url: "/marketplace/categories",
        name: "Categorías",
        slug: "categories",
        icon: "MenuIcon",
      },
      {
        url: "/products",
        name: "Productos",
        slug: "products",
        icon: "PackageIcon",
      },
      {
        url: "/marketplace/products",
        name: "Productos",
        slug: "products",
        icon: "PackageIcon",
      },
    ]
  },
  // TODO Show Direct Payment options when the module is ready and deprecate Service Payments Patch
  // {
  //   header: "Pago Directo",
  //   icon: "MoreHorizontalIcon",
  //   i18n: "Others",
  //   items: [
  //     {
  //       url: "/direct-payment/entities",
  //       name: "Entidades Asociadas",
  //       slug: "direct-payment-entities",
  //       icon: "HomeIcon",
  //     },
  //     {
  //       url: "/direct-payment/cash-out-request",
  //       name: "Solicitud de Cobro",
  //       slug: "direct-payment-cash-out-request",
  //       icon: "FileTextIcon",
  //     },
  //   ]
  // },
  {
    header: "Pagos & Recargas",
    items: [
      {
        url: "/payments/pending",
        name: "Pagos Pendientes",
        slug: "pendingPayments",
        icon: "WatchIcon"
      },
      {
        url: "/requestsPay",
        name: "Solicitud de Pago",
        slug: "requestsPayServices",
        icon: "FileTextIcon"
      },
      {
        url: "/requestsPay/services",
        // name: "Solicitud de Pago (deprecado)",
        name: "Solicitud de Pago",
        slug: "requestsPayServices",
        icon: "FileTextIcon"
      },
      {
        url: "/sa/requestsPay",
        name: "Solicitar Pago",
        slug: "requests",
        icon: "FileTextIcon"
      },
      {
        url: "/recharge/manual",
        name: "Recarga Manual",
        slug: "manual-recharge",
        icon: "PocketIcon"
      },
      {
        url: "/recharge/notices",
        name: "Avisos de Recarga",
        slug: "recharge-notices",
        icon: "PocketIcon"
      },
      {
        url: "/marketplace/payment/request",
        name: "Solicitud de Cobro",
        slug: "marketplace-payment-request",
        icon: "FileTextIcon"
      },
    ]
  },
  {
    header: "Reportes",
    icon: "MoreHorizontalIcon",
    i18n: "Others",
    items: [
      {
        url: '/institutionsOwner',
        name: "Cafeterías",
        icon: "HomeIcon",
        i18n: "Reporte instituciones",
        slug: "report-owner",
      },
      {
        // url: "/reports/sales",
        name: "Ventas",
        icon: "PieChartIcon",
        submenu: [
          {
            url: '/reports/sales',
            name: "General",
            slug: "report-sales",
            i18n: "Reporte Ventas",
          },
          {
            url: '/reports/sales/product',
            name: 'Por Producto',
            slug: 'reports-sales-product',
            i18n: 'Reporte de ventas por producto',
            icon: 'PackageIcon'
          },
          {
            url: '/reports/canceled-sales',
            name: 'Devoluciones',
            slug: 'report-canceled-sales',
            i18n: 'Ventas Canceladas'
          },
          {
            url: '/reports/service/payments/all',
            name: 'Pago de Servicios',
            slug: 'reports-service-payments',
            i18n: 'Ventas Canceladas'
          },
          // {
          //   url: '/reports/close-register',
          //   name: 'Cierres de Caja',
          //   slug: 'report-close-register',
          //   i18n: 'Reporte de cierres de caja'
          // },
        ]
      },
      {
        name: "Cajas",
        icon: "ArchiveIcon",
        submenu: [
          {
            url: '/reports/till/closes',
            name: "Cierres de Caja",
            slug: "till-closes",
            i18n: "Reporte de Cierres de Caja",
          },
          {
            url: '/reports/till/open',
            name: "Cajas Abiertas",
            slug: "till-open",
            i18n: "Reporte de Cajas Abiertas",
          },
        ]
      },
      // {
      //   name: "Pago Directo",
      //   icon: "FileTextIcon",
      //   submenu: [
      //     {
      //       url: "/reports/direct-payments",
      //       name: "Pagos Recibidos",
      //       slug: "direct-payments",
      //       icon: "FileTextIcon",
      //     },
      //     {
      //       url: "/reports/direct-payment/requests",
      //       name: "Solicitudes de Cobro",
      //       slug: "direct-payment-requests",
      //       icon: "FileTextIcon",
      //     },
      //   ]
      // },
      {
        name: "Pagos & Recargas",
        icon: "CreditCardIcon",
        submenu: [
          {
            url: "/reports/requestsAdmin",
            name: "Pagos Realizados",
            slug: "requestsAdmin",
            icon: "ClipboardIcon",
          },
          {
            url: "/reports/requests",
            name: "Solicitudes de Pago",
            slug: "requests",
            icon: "UserIcon",
          },
          {
            url: "/reports/service/payments",
            name: "Pago de Servicios",
            slug: "requests",
            icon: "UserIcon",
          },
          {
            url: "/school/reports/requests",
            // name: "Solicitudes de Pago (deprecado)",
            name: "Solicitudes de Pago",
            slug: "requests",
            icon: "UserIcon",
          },
          {
            url: "/sa/reports/requests",
            name: "Solicitudes de Pago",
            slug: "requests",
            icon: "UserIcon",
          },
          {
            url: "/reports/recharges",
            name: "Recargas Manuales",
            slug: "report-recharges",
            icon: "UserIcon",
          },
        ]
      },
      {
        name: "Ingresos",
        icon: "DollarSignIcon",
        submenu: [
          {
            url: "/reports/incomes",
            name: "Ingresos por Fecha",
            slug: "income",
          },
        ]
      },
      {
        // url: "/reports/inventory",
        name: "Inventario",
        icon: "PackageIcon",
        submenu: [
          {
            url: '/reports/products',
            name: "Productos",
            slug: 'report-productmix',
            i18n: "Reporte de Productos",
          },
          {
            url: '/reports/inventory-transactions',
            name: "Movimientos",
            slug: "report-inventory",
            i18n: "Movimientos I/E",
          }
        ]
      },
      {
        name: "Usuarios",
        icon: "UserIcon",
        submenu: [
          {
            url: "/reports/users/tiptap",
            name: "Tiptap",
            slug: "tiptap",
            icon: "UserIcon",
          },
          {
            url: "/reports/users/institution",
            name: "Administradores",
            slug: "pos",
            icon: "UserIcon",
          },
        ]
      },
      {
        name: "Loyalty",
        icon: "HeartIcon",
        submenu: [
          {
            url: "/reports/loyalty/delivered",
            name: "Premios Entregados",
            slug: "delivered",
          },
        ]
      },
      {
        name: "Garantía de Pago",
        icon: "PercentIcon",
        submenu: [
          {
            url: "/reports/gop/defaultings",
            name: "Morosos",
            slug: "defaultings",
          },
          {
            url: "/reports/gop/user-account-status",
            name: "Estado de Cuenta",
            slug: "user-account-status",
          },
          {
            url: "/reports/gop/penalties",
            name: "Penalidades Recuperadas",
            slug: "penalties",
          },
          {
            url: "/reports/gop/guardians/charges",
            name: "Cobros a Acudientes",
            slug: "charges-guardians",
          },
        ]
      },
      {
        name: "Tienda",
        icon: "ShoppingCartIcon",
        submenu: [
          // For admin pais
          {
            url: "/reports/marketplace/sales",
            name: "Ventas por Tienda",
            slug: "marketplace-sales",
          },
        ]
      },
      {
        url: "/marketplace/reports/sales",
        name: "Ventas",
        slug: "store-sales",
      },
      {
        url: "/marketplace/reports/requests",
        name: "Solicitudes de Cobro",
        slug: "store-requests",
      },
    ]
  },
  {
    header: "Garantía de Pago",
    icon: "MoreHorizontalIcon",
    i18n: "Others",
    items: [
      {
        url: "/gop/request/recurring",
        name: "Solicitudes de Cobro",
        slug: "recurring-requests",
        icon: "DollarSignIcon",
      },
      {
        url: "/gop/request/advance",
        name: "Solicitudes de Adelanto",
        slug: "advance-requests",
        icon: "DollarSignIcon",
      },
      {
        url: "/reports/gop/withholdings",
        name: "Retenciones",
        slug: "withholdings",
        icon: "RotateCcwIcon",
      },
    ]
  },
  {
    header: "Loyalty",
    icon: "MoreHorizontalIcon",
    i18n: "Others",
    items: [
      {
        url: "/rewards",
        name: "Premios",
        slug: "rewards",
        icon: "GiftIcon",
      },
      {
        url: "/Requestrewards",
        name: "Solicitud de Premios",
        slug: "requestrewards",
        icon: "TagIcon",
      },
    ]
  },
  {
    header: "Configuración",
    icon: "MoreHorizontalIcon",
    i18n: "Others",
    items: [
      {
        url: "/config",
        name: "General",
        slug: "general",
        icon: "SettingsIcon",
      },
      {
        url: "/allergies",
        name: "Alergias",
        slug: "allergies",
        icon: "SlashIcon",
      },
      {
        url: "/users",
        name: "Usuarios",
        slug: "users",
        icon: "UserIcon",
      },
    ]
  },
]
