<template>
  <div class="the-navbar__user-meta flex items-center">
    <!-- <div class="con-img mr-3" v-if="(this.institutions.length < 2)">
        <img v-if="institution.img" key="onlineImg" :src="institution.img" alt="user-img" width="40" height="40" class="rounded-full shadow-md block" />
    </div>
    <div class="text-left leading-tight hidden sm:block" v-if="(this.institutions.length < 2)">
        <p class="font-semibold">{{ institution.name }}</p>
        <small>Estudiantes TipTap {{ institution.students_registered }} / {{ institution.students }} | {{ ( institution.students_registered / institution.students * 100).toFixed(1) }}% </small>
    </div> -->
    <vs-dropdown vs-custom-content vs-trigger-click class="the-navbar__user-meta flex items-center cursor-pointer rightx" >
      <!-- <div class="con-img mr-3">
        <img v-if="institution.img" key="onlineImg" :src="institution.img" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div> -->
      <div v-if="institutions.length > 0" class="flex items-center" style="min-width: 280px">
        <div class="con-img mr-3">
          <img v-if="institution.img" key="onlineImg" :src="institution.img" alt="institution-img" width="40" height="40" class="rounded-full shadow-md block" />
        </div>
        <div class="text-left leading-tight hidden sm:block">
          <p class="font-semibold">{{ institution.name }}</p>
          <small v-if="institution.students && institution.students > 0">Estudiantes Tiptap {{ institution.students_registered }} / {{ institution.students }} | {{ ( (institution.students_registered / institution.students) * 100).toFixed(1) }}% </small>
        </div>
      </div>
      <div v-else class="flex items-center" style="min-width: 280px">
        <div class="con-img mr-3">
          <feather-icon icon="HomeIcon" svgClasses="w-10 h-10" width="40px" height="40px" class="rounded-full shadow-md cursor-pointer block" />
        </div>
        <div class="text-left leading-tight hidden sm:block">
          <p class="font-semibold">{{ institution.name }}</p>
        </div>
      </div>
      
      <vs-dropdown-menu class="vx-navbar-dropdown" v-show="institutions.length + schools.length > 1">
          <ul style="min-width: 6rem">
            <div v-if="institutions.length > 0">
              <h6 class="ml-1 p-2">Cafeterías</h6>
              <li v-for="item in institutions" :key="item.id" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="changeInstitution(item)">
                <img v-if="item.image" key="onlineImg" :src="urlImg + item.image" alt="institution-img" width="25" height="25" class="rounded-full shadow-md cursor-pointer block" />
                <span class="ml-2">{{item.name}}</span>
              </li>
            </div>
            <div v-if="schools.length > 0">
              <vs-divider v-if="institutions.length > 0" class="m-1" />
              <h6 v-if="institutions.length > 0" class="ml-1 p-2">Escuelas</h6>
              <li v-for="item in schools" :key="item.id" class="flex py-2 px-2 cursor-pointer hover:bg-primary hover:text-white" @click="changeInstitution(item)">
                <span class="ml-2 block">{{item.name}}</span>
              </li>
            </div>
            

            <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
              <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">Cambiar Password</span>
            </li>

            <vs-divider class="m-1" />

            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
              <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">Logout</span>
            </li> -->
          </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
    <!-- <div class="text-left leading-tight hidden sm:block">
        <p class="font-semibold">{{ institution.name }}</p>
        <small>Estudiantes {{ institution.students }}</small>
    </div> -->
  </div>
</template>

<script>
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"

export default {
  data() {
    return {
      institution: {
        name: "",
        students: "",
        img: null,
        students_registered: 0
      },
      institutions: [],
      schools: [],
      notifications: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
      urlImg: this.$baseUrlImg
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    changeInstitution(institution) { 
      this.$store.dispatch('changeUserInsitution', institution)
        .then(() => {
          this.$router.go();
        })
    }
  },
  created() {
    
    this.institution.name = (!Array.isArray(this.$store.state.AppActiveUser.institution)) ? this.$store.state.AppActiveUser.institution.name : this.$store.state.AppActiveUser.institution[0].name;
    this.institution.students = (!Array.isArray(this.$store.state.AppActiveUser.institution)) ? this.$store.state.AppActiveUser.institution.students : this.$store.state.AppActiveUser.institution[0].students;
    this.institution.img = (!Array.isArray(this.$store.state.AppActiveUser.institution)) ? this.$baseUrlImg + this.$store.state.AppActiveUser.institution.image : this.$baseUrlImg + this.$store.state.AppActiveUser.institution[0].image;

    if (this.$store.state.AppActiveUser.institution) {
      if(!moduleDataListInstitutions.isRegistered) {
        this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
        moduleDataListInstitutions.isRegistered = true
      }

      this.$store.dispatch('dataListInstitutions/getRegisteredStudents', this.$store.state.AppActiveUser.institution.id)
        .then(response => {
          if(response.status && response.data.length) {
            this.institution.students_registered = response.data.filter(s => (s.account.status == 2 || s.account.status == 5) && s.profile.profile_type == 2).length
          }
          
        })
        .catch(error => console.log(error))
      }
    
    if(Array.isArray(this.$store.state.AppActiveUser.institutions)) {this.institutions = this.$store.state.AppActiveUser.institutions;}
    if(Array.isArray(this.$store.state.AppActiveUser.schools)) {this.schools = this.$store.state.AppActiveUser.schools;}

	}
}
</script>
<style lang="css" scoped>
  /* .con-vs-dropdown--menu .vs-dropdown--menu--after {
    left: 20px !important;
    right: 0 !important;
  } */
  .vs-dropdown--menu--after {
    left: 30px !important;
  }
  /* .con-vs-dropdown--menu {
    left: 535px !important;
  } */
</style>
