<template>
  <div class="the-navbar__user-meta flex items-center">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ profile.name }}</p>
      <small>{{ profile.role }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <feather-icon icon="UserIcon" svgClasses="w-10 h-10" width="40px" height="40px" class="rounded-full shadow-md cursor-pointer block" />
      </div>
      <!-- <div class="con-img ml-3">
        <img v-if="profile.img" key="onlineImg" :src="profile.img" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div> -->

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Perfil</span>
          </li> -->

          <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li> -->

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
          @click="changePassword">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Cambiar Password</span>
          </li>

          <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Wish List</span>
          </li> -->

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profile: {
        name: "",
        role: "",
        // img: "/img/avatar-s-11.1a620230.jpg"
      },
      notifications: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser.profile
    }
  },
  methods: {
    logout() {
      //this.$router.push('/login').catch(() => {})
      this.$store.dispatch("auth/logout");
    },
    changePassword() {
      this.$router.push('/change-password').catch(() => {})
    }
  },
  created() {
    
    let profile = this.$store.state.AppActiveUser.profile
    let profile_type = this.$store.state.AppData.profileTypes.find(x => x.profile_type == profile.profile_type)
    this.profile.name = profile.name + ' ' + profile.last_name
    this.profile.role = profile_type ? profile_type.profile_type_description : "";

	}
}
</script>
